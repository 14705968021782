import "./App.css";
import FreestyleImg from "./assets/freestyle.jpg";
import Screen1Img from "./assets/screen-1.svg";
import Screen2Img from "./assets/screen-2.svg";
import Persona1Img from "./assets/persona-1.svg";
import Persona2Img from "./assets/persona-2.svg";
import Panel1Img from "./assets/panel-1.png";
import Panel2Img from "./assets/panel-2.png";
import Panel3Img from "./assets/panel-3.jpg";
import Panel4Img from "./assets/panel-4.png";
import Panel5Img from "./assets/panel-5.jpg";
import Panel6Img from "./assets/panel-6.jpg";
import Machine from "./assets/machine.svg";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function App() {
  return (
    <div className="App">
      <Navbar expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#introduction">
            <b>Personas and Storyboarding Project</b>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#introduction">Introduction</Nav.Link>
              <Nav.Link href="#preparation">Preparation</Nav.Link>
              <Nav.Link href="#observations">Observations</Nav.Link>
              <Nav.Link href="#personas">Personas</Nav.Link>
              <Nav.Link href="#storyboard">Storyboard</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="parent">
        <section className="section" id="introduction">
          <Container>
            <p className="heading">Introduction</p>
            <p id="intro-text">
              This CSCI 1300 project asks students to research a public
              interface and analyze how users interact with it. My interface of
              choice will be the drink machines in the Ratty, which many
              students use to dispense their beverages every day. These machines
              are a variant of the "Coca-Cola Freestyle" machines found in
              commercial restaurant chains.
            </p>
            <Container>
              <div className="d-flex justify-content-center">
                <img
                  src={FreestyleImg}
                  id="freestyle"
                  alt="A shot of the commercial Coca-Cola Freestyle machines."
                ></img>
              </div>
              <p className="alt-text">
                A photo of the commercial Coca-Cola Freestyle machines.
              </p>
            </Container>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="preparation">
          <Container>
            <p className="heading">Preparation</p>
            <p className="subheading">
              Below are the interview questions that I asked users, separated
              into two categories.
            </p>
            <Row xs={1} md={2}>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Pre-use Interview Questions</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>Where do you start?</li>
                        <li>Where are you first drawn to on the menu page?</li>
                        <li>
                          Where are you first drawn to on the drink selection
                          page?
                        </li>
                        <li>
                          How do you go about selecting drinks to dispense?
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Post-use Interview Questions</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>How did you feel about your experience?</li>
                        <li>
                          Did the interface give you all the info you needed?
                        </li>
                        <li>Describe the interface in three adjectives.</li>
                        <li>Do you have any additional comments?</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container>
            <p className="subheading">
              Below are the diagrams of my interface, which attempts to solve
              the problem of getting a variety of drinks conveniently. The key
              interactive components of the physical machine are a touchscreen
              for digital displays, a button users push to dispense drinks, and
              a level users push to dispense ice. There are two main displays
              shown on the touchscreen. The home page consists of all the drink
              brands and a few additional features that users can interact with
              (labeled on the diagram). By touching a drink brand button, users
              will be brought to the selection page, which consists of all the
              drink variations and a few additional features that users can
              interact with (labeled on the diagram). After finalizing their
              selection by touching the buttons on the screen, users must use
              the physical button to get the drink.
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={Machine}
                id="machine"
                alt="A diagram of the physical drink machine interface."
              ></img>
            </div>
            <p className="alt-text">
              A diagram of the physical drink machine interface.
            </p>
          </Container>
          <Container>
            <Row xs={1} md={2}>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={Screen1Img}
                    id="screen-1"
                    alt="A diagram of the drink machine's homepage."
                  ></img>
                </div>
                <p className="alt-text">
                  A diagram of the drink machine's homepage.
                </p>
              </Col>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={Screen2Img}
                    id="screen-2"
                    alt="A diagram of the drink machine's selection page."
                  ></img>
                </div>
                <p className="alt-text">
                  A diagram of the drink machine's selection page.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="observations">
          <Container>
            <p className="heading">Observation</p>
            <p className="subheading">
              Below are the summaries of my research findings, separated into
              two categories.
            </p>
            <Row xs={1} md={2}>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Summary of User Observations</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>
                          Users tried to dispense ice first before touching the
                          digital screen.
                        </li>
                        <li>
                          Users did not utilize the app-scanning feature for
                          contactless dispensing.
                        </li>
                        <li>
                          Users did not press the back button after they got
                          their drinks.
                        </li>
                        <li>
                          Users took more time with the interface when the drink
                          they wanted was missing.
                        </li>
                        <li>
                          Some users used the calorie filter and calorie info
                          table feature to pick drinks.
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>
                      Summary of User Responses to Questions
                    </Card.Title>
                    <Card.Text>
                      <ul>
                        <li>
                          Users liked to get ice first before using the
                          touchscreen.
                        </li>
                        <li>
                          Users were drawn to the drink buttons over other
                          features of the interface.
                        </li>
                        <li>
                          Users wanted a way to check if the machine had ice
                          through the display.
                        </li>
                        <li>
                          Users thought that the calories checking options were
                          confusing to understand.
                        </li>

                        <li>
                          Users felt that the process was inconvenient when a
                          drink variation was unavailable.
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="personas">
          <Container>
            <p className="heading">Personas</p>
            <p className="subheading">
              Below are two personas that were created from the research
              information I gathered.
            </p>
            <Row xs={1} md={2}>
              <Col className="card-col">
                <Card>
                  <Card.Img
                    className="persona"
                    variant="top"
                    src={Persona1Img}
                  />
                  <Card.Body>
                    <Card.Title>Rushed Rachel</Card.Title>
                    <Card.Text>
                      Rushed Rachel is a student with a class that ends at 11:50
                      AM. She arrives at the Ratty during rush hour and has to
                      wait in line for a long time, resulting in her needing to
                      get a drink quickly to snag a seat before they fill up.
                      <br />
                      The main issue with the interface that Rachel faces during
                      her daily interactions is a lack of information about
                      which drink options are unavailable, causing an
                      unnecessary increase in usage time. This persona
                      represents the section of the user base that generally
                      knows what drinks they want before using the machine and
                      focuses on obtaining this beverage choice as fast as
                      possible. Many of these users discussed the lack of
                      important information the machine provided on its screen;
                      currently, the machine only notifies users if all variants
                      of a drink are out.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card>
                  <Card.Img
                    className="persona"
                    variant="top"
                    src={Persona2Img}
                  />
                  <Card.Body>
                    <Card.Title>Healthy Harold</Card.Title>
                    <Card.Text>
                      Healthy Harold is a student-athlete with a strict training
                      regimen and diet. He doesn't have any morning classes,
                      instead arriving at the Ratty early after his workout to
                      avoid the long lines and get ample time for choosing.
                      <br />
                      The main issue with the interface that Harold faces during
                      his daily interactions is a confusing display of the
                      calorie information for his drinks, causing an unnecessary
                      increase in usage time. This persona represents the
                      section of the user base that generally doesn't know what
                      drinks they want before using the machine and focuses on
                      choosing healthier options. Many of these users discussed
                      the amount of confusing information on the screen;
                      currently, the machine only presents specific calorie
                      amounts on the selection page in a large table with many
                      serving sizes.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="storyboard">
          <Container>
            <p className="heading">Storyboard</p>
            <p className="subheading">
              Below is the storyboard for Rushed Rachel, which shows a common
              situation in her life involving the use of this interface.
            </p>
            <Row xs={1} md={3}>
              <Col className="card-col">
                <Card className="storyboard-panel">
                  <Card.Img variant="bottom" src={Panel1Img} />
                  <Card.Body>
                    <Card.Title>Scene 1</Card.Title>
                    <Card.Text>
                      On a busy Monday morning, Rushed Rachel leaves her class
                      at 11:50 AM and heads over to the Ratty as soon as she
                      can. However, there is already a long line outside the
                      building when she arrives.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card className="storyboard-panel">
                  <Card.Img variant="top" src={Panel2Img} />
                  <Card.Body>
                    <Card.Title>Scene 2</Card.Title>
                    <Card.Text>
                      After swiping her student ID to get inside the dining
                      hall, Rachel joins one of the two comfort lines, which
                      have already wrapped around the back wall. She will have
                      to wait again for yet another line.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card className="storyboard-panel">
                  <Card.Img variant="top" src={Panel3Img} />
                  <Card.Body>
                    <Card.Title>Scene 3</Card.Title>
                    <Card.Text>
                      With her plate of food in hand, Rachel goes to the drink
                      machine and tries to dispense ice using the physical lever
                      multiple times to no avail, as it has already been
                      emptied. She is annoyed that she wasted this time.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card className="storyboard-panel">
                  <Card.Img variant="top" src={Panel4Img} />
                  <Card.Body>
                    <Card.Title>Scene 4</Card.Title>
                    <Card.Text>
                      From the homepage, Rachel can see that the machine is out
                      of her drink brand of choice, Fanta, by the grayed-out
                      button. She decides to check some Minute Maid lemonade
                      variations, clicking the respective button on the screen
                      to get to the drink selection page.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card className="storyboard-panel">
                  <Card.Img variant="top" src={Panel5Img} />
                  <Card.Body>
                    <Card.Title>Scene 5</Card.Title>
                    <Card.Text>
                      Once on the drink selection page for lemonade, she sees
                      that the orange variation that she wanted is unavailable
                      as well as it is grayed out. Feeling more frustrated and
                      rushed by the line forming behind her, she decides on pink
                      lemonade, tapping on the on-screen button to select it and
                      then the physical button to pour the drink into her cup.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card className="storyboard-panel">
                  <Card.Img variant="top" src={Panel6Img} />
                  <Card.Body>
                    <Card.Title>Scene 6</Card.Title>
                    <Card.Text>
                      Rachel angrily rushes off to grab a seat with her food and
                      drink in hand. She forgets to press the back button to go
                      to the homepage, which causes the next person to have to
                      wait a few extra seconds for the machine to automatically
                      switch the display back.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <p className="subheading">
              The moral of our story is to "treat others how you want to be
              treated". Rachel was very frustrated throughout the whole process
              because she was running late. However, because she didn't click
              the back button after she got her drink, it adds additional time
              to the next person.
            </p>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default App;
